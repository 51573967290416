
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateRERPayload, { CreateREREmployeePayload } from "@/dto/request/rer/CreateRERPayload";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import { namespace } from "vuex-class";
import RERService from "@/services/request/RERService";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import { PayPeriodType, PayType } from "@/constants/TarifficationConstants";
import PortalDate from "@/components/common/PortalDate.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import SelectOption from "@/components/common/SelectOption";
import BranchDto from "@/components/profile/branches/BranchDtos";
import AddressDTO from "@/dto/AddressDTO";
import CompanyService from "@/services/CompanyService";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");

@Component({
  components: {
    PortalDate,
    PortalSelect,
    SimpleCompanySearch,
    PortalCheckbox,
    PhoneInput,
    PortalInput,
    PortalCollapse,
    CompanySearch
  },
  computed: {
    PayPeriodType() {
      return PayPeriodType;
    },
    PayType() {
      return PayType;
    },
    SelectOption() {
      return SelectOption;
    }
  }
})
export default class CreateRER extends Vue {
  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Prop()
  onCreated!: () => void;

  private payload = new CreateRERPayload();
  private newEmployee = new CreateREREmployeePayload();
  private branches: BranchDto[] = [];
  private expandedEmployeeIndices: { [key: number]: boolean } = {};
  private addMode = true;
  @Prop({default: false})
  private customerMode!: boolean;

  hasOfficer = true;
  hasLegalAddress = true;

  mounted(): void {
    if (this.customerMode) {
      this.payload.companyId = Workspaces.getCurrent.id;
      this.loadBranches(this.payload.companyId);
    }
  }

  private selectCompany(company: CompanyDTO) {
    this.payload.companyId = company.id;
    this.loadBranches(this.payload.companyId).then(
        ok => {
          this.checkOfficerPresence();
          this.checkLegalAddress();
        },
        err => processError(err, this)
    );
  }

  private loadBranches(id: number) {
    return CompanyService.getBranches(id).then(response => {
      this.branches = response.data;
      this.addMode = true;
    }).then(
        ok => CompanyService.getHeadQuarter(id).then(response => {
          this.branches.push(response.data)
        })
    );
  }

  private checkOfficerPresence() {
    return CompanyService.getOfficer(this.payload.companyId!).then(
        ok => {
          this.hasOfficer = !!ok.data;
        },
        err => {
          processError(err, this);
        }
    );
  }

  private checkLegalAddress() {
    this.hasLegalAddress = this.branches.some(branch => branch.headQuarter && !!branch.legalAddress);
    CompanyService.getHeadQuarter(this.payload.companyId!).then(
        ok => {
          this.hasLegalAddress = !!ok.data && ok.data.headQuarter && !!ok.data.legalAddress;
        },
        err => {
          processError(err, this);
        }
    );
  }

  private toggleAddMode() {
    this.addMode = !this.addMode;
  }

  private toggleDetails(index: number) {
    this.$set(this.expandedEmployeeIndices, index, !this.expandedEmployeeIndices[index]);
  }

  private getBranchTitleAndAddressById(addressId: number) {
    for (const branch of this.branches as BranchDto[]) {
      const addresses = [
        branch.legalAddress,
        branch.mailingAddress,
        branch.actualAddress,
        branch.registrationAgentAddress
      ];

      for (const address of addresses) {
        if (address && address.id === addressId) {
          return {
            title: branch.title,
            address: address.fullAddress
          };
        }
      }
    }
    return { title: 'Not Found', address: 'Not Found' };
  }

  private addEmployee() {
    ifValid(this, () => {
      this.payload.employees.push(this.newEmployee);
      this.newEmployee = new CreateREREmployeePayload();
      this.addMode = false;
    });
  }

  private create() {
    this.startLoading();
    RERService.create(this.payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    );
  }

  private get buildWorkplaces(): Array<SelectOption> {
    let workplaces: Array<SelectOption> = [];
    this.newEmployee.branchesId
        .map(id => this.branches.find(it => it.id == id))
        .map(it => this.assembleAddresses(it!))
        .map(list => list.filter(address => !!address && !!address.fullAddress).map(address => SelectOption.builder().title(address!.fullAddress).value(address!.id).build()))
        .forEach(list => workplaces.push(...list));
    return workplaces;
  }

  private assembleAddresses(branch: BranchDto) {
    let res = [];
    if (branch.legalAddress && branch.legalAddress.fullAddress) {
      res.push(branch.legalAddress);
    }
    if (branch.actualAddress && branch.actualAddress.fullAddress) {
      res.push(branch.actualAddress);
    }
    if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
      res.push(branch.mailingAddress);
    }
    if (!AddressDTO.isEmpty(branch.registrationAgentAddress)) {
      res.push(branch.registrationAgentAddress);
    }
    return res;
  }

  private buildPayPeriodTypes(): Array<SelectOption> {
    let payPeriodTypes: Array<SelectOption> = [];
    const periodTypes = Object.values(PayPeriodType);
    periodTypes.forEach(period => {
      payPeriodTypes.push(
          SelectOption.builder()
              .title(`${period}`)
              .value(period)
              .build()
      );
    });
    return payPeriodTypes;
  }

  private buildPayTypes(): Array<SelectOption> {
    let payTypes: Array<SelectOption> = [];
    const types = Object.values(PayType);
    types.forEach(type => {
      payTypes.push(
          SelectOption.builder()
              .title(`${type}`)
              .value(type)
              .build()
      );
    });
    return payTypes;
  }

  get buildBranches(): Array<SelectOption> {
    return this.branches.map(branch => SelectOption.builder().title(branch.title).value(branch.id).build());
  }

}
