import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";

export default class CreateRERPayload {

    companyId: number | null = null;

    toBeFilledByCustomer = false;

    employees: Array<CreateREREmployeePayload> = [];

    constructor(init? : Partial<CreateRERPayload>) {
        Object.assign(this, init);
    }
}

export class CreateREREmployeePayload {

    name: string | null = null;
    phone: string | null = null;
    email: string | null = null;
    actualizationOnly = false;
    workPlacesId: number[] = [];
    payPeriodType: PayPeriodType | null = null;
    payType: PayType | null = null;
    wageRate!: number;
    isRemote!: boolean;
    employmentStart!: Date;
    branchesId: number[] = [];


    constructor(init?: Partial<CreateREREmployeePayload>) {
        Object.assign(this, init);
    }

}

